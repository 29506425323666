import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const DefaultErrorPage = () => {
  const { t } = useTranslation('checkout');

  return (
    <ErrorPage title={t('CheckoutPage.Errors.Default.title')}>
      <p>{t('CheckoutPage.Errors.Default.text')}</p>
    </ErrorPage>
  );
};

export default DefaultErrorPage;
