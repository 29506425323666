import { useMemo } from 'react';
import cx from 'classnames';

/*
 * TODO: write documentation and explain why the
 * useMemo hook is used and the input must be an function
 */
const useClassNames = (input, args) => useMemo(() => cx(input()), args);

export default useClassNames;
