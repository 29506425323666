import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '@medflex/components/stories/Spinner';
import { FinalizeSuccess, SubscriptionJS } from '../types/billwerk';
import { useSubscriptionJS } from '../hooks/useSubscriptionJs';

const PSPRedirect = () => {
  const { t } = useTranslation('checkout');
  const navigate = useNavigate();

  const [subscriptionJS, setSubscriptionJS] = useState<SubscriptionJS | undefined>(undefined);

  useSubscriptionJS(subscriptionJsInstance => setSubscriptionJS(subscriptionJsInstance));

  useEffect(() => {
    if (subscriptionJS) {
      subscriptionJS.finalize(
        (finalizeSuccess: FinalizeSuccess) =>
          navigate(
            `/checkout/success?orderId=${finalizeSuccess.OrderId}&grossTotalAmount=${finalizeSuccess.GrossTotal}`,
          ),
        () => navigate('/errors/unknown'),
      );
    }
  }, [subscriptionJS]);

  return (
    <div className="pt-16">
      <div className="flex items-center justify-center mb-12">
        <Spinner size="4xl" />
        <span className="ml-5 text-4xl">
          <strong>{t('CheckoutPage.Finalize.text')}</strong>
        </span>
      </div>
    </div>
  );
};

export default PSPRedirect;
