import InputCheckbox from '@medflex/components/stories/InputCheckbox';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

export interface CheckboxInformation {
  agreePrivacy: boolean;
  agreeTerms: boolean;
}

const CheckboxForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation('checkout');

  const requiredMessage: string = t('CheckoutPage.Form.Errors.required');

  return (
    <div>
      <div className="flex flex-row mb-4">
        <div className="pr-3">
          <InputCheckbox
            id="privacy"
            {...register('agreePrivacy', { required: requiredMessage })}
            state={errors.agreePrivacy ? 'invalid' : 'default'}
          />
        </div>
        <div>
          <Trans t={t} i18nKey="CheckoutPage.Form.Fields.privacy">
            <a href={t('CheckoutPage.Form.Fields.privacyUrl')} target="_blank" rel="noreferrer">
              Datenschutzerklärung
            </a>
          </Trans>
        </div>
      </div>

      <div className="flex flex-row">
        <div className="pr-3">
          <InputCheckbox
            id="terms"
            {...register('agreeTerms', { required: requiredMessage })}
            state={errors.agreeTerms ? 'invalid' : 'default'}
          />
        </div>
        <div>
          <Trans t={t} i18nKey="CheckoutPage.Form.Fields.terms">
            <a href={t('CheckoutPage.Form.Fields.termsUrl')} target="_blank" rel="noreferrer">
              Geschäftsbedingungen
            </a>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default CheckboxForm;
