import * as React from 'react';
import * as PropTypes from 'prop-types';
import IconWrapper from './IconWrapper';

const IconTag = props =>
  React.createElement(
    IconWrapper,
    props,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className="block w-full h-full fill-current"
    >
      <path d="M0 10V2l2-2h8l10 10-10 10L0 10zm4.5-4a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
    </svg>,
  );
IconTag.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};
export default IconTag;
