import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EMAIL_REGEX } from '@medflex/shared-utils';
import useClassNames from '@medflex/hooks/useClassNames';

const InputField = forwardRef(
  (
    {
      afterInput,
      beforeInput,
      className,
      disabled,
      innerRef,
      inputAction,
      inputActionClassname,
      inputClassName,
      onBlur,
      onChange,
      onFocus,
      onInput,
      pattern,
      label,
      secondaryLabel,
      state = 'default',
      value,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const isControlled = !Object.keys(props).includes('defaultValue');
    const { defaultValue, type } = props;
    const [isFocused, setIsFocused] = React.useState(false);
    const [val, setVal] = React.useState('');

    useEffect(() => {
      if (isControlled) setVal(value);
    }, [value, isControlled]);

    const inputWrapperClassnames = useClassNames(
      () => [
        'flex',
        'items-center',
        'overflow-hidden',
        'font-normal',
        'border',
        'rounded',
        'border-solid',
        'bg-white',
        'autofill:bg-white',
        label && 'mt-2',
        inputAction && 'relative',
        !readOnly && !disabled && !isFocused && state === 'default' && 'border-slate-400',
        !readOnly && !disabled && !isFocused && state === 'widget' && 'border-slate-200',
        !readOnly && !disabled && !isFocused && state === 'invalid' && 'border-red-600',
        !readOnly && !disabled && !isFocused && state === 'valid' && 'border-green-500',
        !readOnly && !disabled && isFocused && 'border-blue-500',
        className,
      ],
      [isFocused, disabled, className, state, inputAction],
    );
    const inputClassnames = useClassNames(
      () => [
        'form-input',
        'leading-tight',
        'p-3',
        'flex-1',
        'font-normal',
        'outline-none',
        'placeholder-slate-500',
        'bg-white',
        'autofill:bg-white',
        (readOnly || disabled) &&
          `border-slate-300 \
          cursor-not-allowed \
          pointer-events-none\
          text-slate-400 \
          cursor-not-allowed \
          bg-slate-50`,
        inputClassName,
      ],
      [disabled, inputClassName, state, isFocused],
    );
    const inputActionClassnames = useClassNames(
      () => ['absolute', 'right-4', 'top-3', inputActionClassname],
      [isFocused, disabled, className, state],
    );

    let customPattern = pattern;
    if (props.type === 'email') {
      const stringEmailRegex = EMAIL_REGEX.toString();
      customPattern = stringEmailRegex.substring(1, stringEmailRegex.length - 1);
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {label || type === 'search' ? (
          <label
            htmlFor={label}
            data-component="InputField"
            className="font-semibold leading-5 w-full"
          >
            {label}
            {secondaryLabel && (
              <span className="ml-1 font-normal text-slate-400">{secondaryLabel}</span>
            )}
            <span className={inputWrapperClassnames}>
              {beforeInput}
              <input
                {...props}
                ref={innerRef || ref}
                className={inputClassnames}
                onFocus={event => {
                  setIsFocused(true);
                  if (onFocus) {
                    onFocus(event);
                  }
                }}
                onInput={event => {
                  if (onInput) {
                    onInput(event);
                  }
                }}
                onChange={event => {
                  setVal(event.target.value);
                  if (onChange) {
                    onChange(event);
                  }
                }}
                onBlur={event => {
                  setIsFocused(false);
                  if (onBlur) {
                    onBlur(event);
                  }
                }}
                {...(isControlled ? { value: val } : { defaultValue })}
                disabled={disabled}
                pattern={pattern}
              />
              {inputAction && <div className={inputActionClassnames}>{inputAction}</div>}
              {afterInput}
            </span>
          </label>
        ) : (
          <span data-component="InputField" className={inputWrapperClassnames}>
            {beforeInput}
            <input
              {...props}
              ref={innerRef || ref}
              className={inputClassnames}
              onFocus={event => {
                setIsFocused(true);
                if (onFocus) {
                  onFocus(event);
                }
              }}
              onInput={event => {
                if (onInput) {
                  onInput(event);
                }
              }}
              onChange={event => {
                setVal(event.target.value);
                if (onChange) {
                  onChange(event);
                }
              }}
              onBlur={event => {
                setIsFocused(false);
                if (onBlur) {
                  onBlur(event);
                }
              }}
              {...(isControlled ? { value: val } : { defaultValue })}
              disabled={disabled}
              pattern={customPattern}
            />
            {inputAction && <div className={inputActionClassnames}>{inputAction}</div>}
            {afterInput}
          </span>
        )}
      </>
    );
  },
);

InputField.displayName = 'InputField';

InputField.propTypes = {
  afterInput: PropTypes.node,
  beforeInput: PropTypes.node,
  inputAction: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  secondaryLabel: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  inputClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  inputActionClassname: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  state: PropTypes.oneOf(['default', 'invalid', 'valid', 'widget']),
  type: PropTypes.string,
  value: PropTypes.string,
};

export default InputField;
