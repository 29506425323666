import { PhoneNumberTags, PhoneNumberTypes } from '@medflex/shared-types';
import { phoneNumber } from '@medflex/commons';

export const phoneNumberTypeToTag = (type: number): PhoneNumberTags => {
  if (type === PhoneNumberTypes.LANDLINE) {
    return PhoneNumberTags.LANDLINE;
  }

  if (type === PhoneNumberTypes.MOBILE) {
    return PhoneNumberTags.MOBILE;
  }

  return PhoneNumberTags.OTHER;
};

export const isValidPhoneNumber = (number: string) => phoneNumber.transformableToE164(number);

export const isMobilePhoneNumber = (number: string) => {
  if (!phoneNumber.transformableToE164(number)) {
    return false;
  }

  const numberType = phoneNumber.getNumberType(number);

  return PhoneNumberTypes.MOBILE === numberType;
};
