import { initReactI18next } from 'react-i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import i18n from '@medflex/i18n';
import Logo from '@medflex/components/stories/Logo';
import App from './App';

i18n({ plugins: [initReactI18next] });

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <div className="flex flex-col h-screen justify-between">
      <header className="flex-initial">
        <div className="container mx-auto px-4">
          <Logo className="my-6" />
        </div>
      </header>

      <main className="mb-auto flex-auto">
        <div className="h-full content-background">
          <div className="container mx-auto">
            <App />
          </div>
        </div>
      </main>

      <footer className="flex-initial">
        <div className="container mx-auto text-center px-4 py-10">
          <p className="flex flex-col items-center justify-center lg:flex-row space-x-0 lg:space-x-16 space-y-6 lg:space-y-0 flex-shrink-0">
            <span>© 2022 medflex GmbH</span>
            <a href="https://www.medflex.de/impressum" target="_blank" rel="noreferrer">
              Impressum
            </a>
            <a href="https://www.medflex.de/datenschutz" target="_blank" rel="noreferrer">
              Datenschutz
            </a>
            <a href="https://www.medflex.de/agb#agb-behandler" target="_blank" rel="noreferrer">
              AGB & Nutzungsbedingungen
            </a>
          </p>
        </div>
      </footer>
    </div>
  </React.StrictMode>,
);
