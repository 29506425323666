import * as React from 'react';
import * as PropTypes from 'prop-types';
import IconWrapper from './IconWrapper';

const IconCheveronDown = props =>
  React.createElement(
    IconWrapper,
    props,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className="block w-full h-full fill-current"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>,
  );
IconCheveronDown.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};
export default IconCheveronDown;
