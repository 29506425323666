import * as React from 'react';
import * as PropTypes from 'prop-types';
import IconWrapper from './IconWrapper';

const IconCheckmarkOutline = props =>
  React.createElement(
    IconWrapper,
    props,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className="block w-full h-full fill-current"
    >
      <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
    </svg>,
  );
IconCheckmarkOutline.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};
export default IconCheckmarkOutline;
