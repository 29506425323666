import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { IconCheveronDown } from '../Icons';

const DropDown = forwardRef(
  (
    {
      options,
      state,
      className,
      onChange,
      placeholder,
      hasEmptyDefault,
      hasArrow,
      initialValue,
      optionLabels,
      children,
      ...props
    },
    ref,
  ) => {
    const isControlled = !Object.keys(props).includes('defaultValue');
    const { defaultValue } = props;
    const opts = useMemo(
      () => (hasEmptyDefault || placeholder ? ['', ...options] : options),
      [options, hasEmptyDefault, placeholder],
    );
    const optLabels = useMemo(
      () =>
        (optionLabels &&
          (hasEmptyDefault || placeholder ? [placeholder || '', ...optionLabels] : optionLabels)) ||
        null,
      [optionLabels, hasEmptyDefault],
    );
    const [value, setValue] = useState(initialValue || opts[0] || '');

    useEffect(() => {
      if (initialValue) setValue(initialValue);
    }, [initialValue]);

    const cn = useClassNames(
      () => [
        'block',
        'appearance-none',
        'w-full',
        'border',
        'p-3',
        'pr-8',
        'rounded',
        'leading-tight',
        'focus:outline-none',
        'focus:border-blue-500',
        props.disabled ? 'text-slate-400 cursor-not-allowed' : 'cursor-pointer',
        state === 'default' && 'border-slate-400',
        state === 'widget' && 'border-slate-200',
        state === 'invalid' && 'border-red-600',
        state === 'valid' && 'border-green-500',
        className,
      ],
      [className, state, props.disabled],
    );

    const handleChange = e => {
      setValue(e.target.value);
      onChange(e);
    };

    return (
      <div className="relative">
        <select
          ref={ref}
          className={cn}
          id="grid-state"
          {...(isControlled ? { value } : { defaultValue })}
          onChange={handleChange}
          {...props}
        >
          {opts.length > 0 && opts
            ? opts.map((option, index) => (
                <option
                  key={option}
                  value={option}
                  disabled={option === '' || option === placeholder}
                >
                  {optLabels ? optLabels[index] : option}
                </option>
              ))
            : children}
        </select>
        {hasArrow && (
          <div
            className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ${
              props.disabled ? 'text-slate-400' : 'text-slate-600'
            }`}
          >
            <IconCheveronDown />
          </div>
        )}
      </div>
    );
  },
);

DropDown.displayName = 'DropDown';

DropDown.defaultProps = {
  options: [],
  state: 'default',
  onChange: () => {},
  hasArrow: true,
  disabled: false,
};

DropDown.propTypes = {
  state: PropTypes.oneOf(['default', 'widget', 'invalid', 'valid']),
  optionLabels: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  hasEmptyDefault: PropTypes.bool,
  disabled: PropTypes.bool,
  hasArrow: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.string,
  defaultValue: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
};

export default DropDown;
