export const maxAge = 120;

export const isValidDateOfBirth = (dateOfBirth: Date): boolean => {
  if (!(dateOfBirth instanceof Date) || Number.isNaN(dateOfBirth)) {
    return false;
  }

  const currentDate = new Date();

  const minDate = new Date();
  minDate.setFullYear(currentDate.getFullYear() - maxAge);

  return dateOfBirth >= minDate && dateOfBirth < currentDate;
};
