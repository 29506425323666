import { RequestTypes } from './pamRequest.types';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}

export enum UserTypes {
  PHYSICIAN = 'physician',
  STAFF = 'staff',
  PATIENT = 'patient',
}

export enum BookmarkTypes {
  PRESCRIPTIONS = 'Prescriptions',
  INBOX_SEARCH_QUERY = 'InboxSearchQuery',
}

export enum UserTagKeys {
  APPOINTMENT = RequestTypes.APPOINTMENT,
  FINDINGS_INQUIRY = RequestTypes.FINDINGS_INQUIRY,
  GENERAL_INQUIRY = RequestTypes.GENERAL_INQUIRY,
  REFERRAL = RequestTypes.REFERRAL,
  REQUEST_FOLLOW_UP_PRESCRIPTION = RequestTypes.REQUEST_FOLLOW_UP_PRESCRIPTION,
  CONNECT_TO_PHYSICIAN = RequestTypes.CONNECT_TO_PHYSICIAN,
  PHONE = RequestTypes.PHONE,
  DOCUMENT = RequestTypes.DOCUMENT,
}

export enum ClientIDs {
  physician = 'medflex-web-physician',
  patient = 'medflex-web-patient',
  staff = 'medflex-web-staff',
}
