import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Button from '@medflex/components/stories/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackClick, trackEcommerceView } from '../utils/tracking';
import { useSubscriptionJS } from '../hooks/useSubscriptionJs';
import {
  CustomerData,
  DirectDebit,
  FinalizeSuccess,
  Order,
  OrderResponse,
  OrderWrapper,
  PaymentService,
  SignupService,
  SubscriptionJS,
} from '../types/billwerk';
import CustomerInformationForm, {
  CustomerInformation,
} from './CheckoutForm/CustomerInformationForm';
import PaymentInformationForm, { PaymentInformation } from './CheckoutForm/PaymentInformationForm';
import PlanAdditionalInformation from './Plan/PlanAdditionalInformation';
import PlanCouponCode from './Plan/PlanCouponCode';
import PlanDescription from './Plan/PlanDescription';
import PlanOverview from './Plan/PlanOverview';
import PlanPricing from './Plan/PlanPricing';
import './CheckoutPage.css';
import CheckboxForm, { CheckboxInformation } from './CheckoutForm/CheckboxForm';
import OrderSupportInfoText from './OrderSupportInfoText';

export interface CheckoutInput
  extends CustomerInformation,
    PaymentInformation,
    CheckboxInformation {}

type ReducedAddress = {
  country: string;
};

type ReducedCustomer = {
  vatId: string;
  address: ReducedAddress;
};

const CheckoutPage = () => {
  const methods = useForm<CheckoutInput>({ mode: 'onBlur' });
  const {
    getValues,
    formState: { errors, isValidating },
  } = methods;

  const couponCodeActive = process.env.REACT_APP_COUPON_CODE_ACTIVE?.toLowerCase() === 'true';

  const [order, setOrder] = useState<Order>();
  const [subscriptionJS, setSubscriptionJS] = useState<SubscriptionJS | undefined>(undefined);
  const [signupService, setSignupService] = useState<SignupService | undefined>(undefined);
  const [paymentService, setPaymentService] = useState<PaymentService | undefined>(undefined);
  const [submitButtonActive, setSubmitButtonActive] = useState<boolean>(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [licenseId, setLicenseId] = useState<string | undefined>(undefined);
  const [licensesAmount, setLicensesAmount] = useState<number | undefined>(undefined);
  const [triggerPreviewFlag, setTriggerPreviewFlag] = useState<boolean>(false);

  const { planVariantId } = useParams();
  const navigate = useNavigate();

  useSubscriptionJS(subscriptionJSInstance => setSubscriptionJS(subscriptionJSInstance));

  const { t } = useTranslation('checkout');

  const [orderInitialized, setOrderInitialized] = useState<boolean>(false);
  const [licenseChangedTracked, setLicenseChangedTracked] = useState<boolean>(true);

  const pageViewEvent = () => {
    if (order) {
      trackEcommerceView(false, order.PlanVariantId, order.RecurringFee.Name, order.Total);
    }
  };

  const isMedflexPro = () => order?.RecurringFee.Name === 'medflex Pro';

  // tracks page view in matomo for all plans besides medflex Pro
  useEffect(() => {
    if (order && !isMedflexPro() && !orderInitialized) {
      setOrderInitialized(true);
      pageViewEvent();
    }
  }, [order]);

  // This effect sets the pre condition to track the license change in matomo
  useEffect(() => {
    if (licensesAmount) {
      setLicenseChangedTracked(false);
    }
  }, [licensesAmount]);

  // tracks page view in matomo for medflex Pro. Every change of the license amount is tracked for medflex Pro
  useEffect(() => {
    if (order && isMedflexPro() && !licenseChangedTracked) {
      setLicenseChangedTracked(true);
      pageViewEvent();
    }
  }, [order]);

  // Initializes SignupService and PaymentService after SubscriptionJS is loaded
  useEffect(() => {
    if (!subscriptionJS) {
      return;
    }

    setSignupService(new subscriptionJS.Signup());

    const paymentServiceInstance: PaymentService = new subscriptionJS.Payment(
      {
        publicApiKey: process.env.REACT_APP_BILLWERK_PUBLIC_API_KEY || 'NOT_SET',
        providerReturnUrl: process.env.REACT_APP_PROVIDER_RETURN_URL || 'NOT_SET',
      },
      () => setPaymentService(paymentServiceInstance),
      () => navigate(`/errors/order-system?planVariantId=${planVariantId}`),
    );
  }, [subscriptionJS]);

  // license of main account is part of planvariant
  // therefore we have to order one component (staff license) less
  const calculateLicensesAmount = () => licensesAmount! - 1;

  const licenses = () =>
    licensesAmount! > 1 && licenseId
      ? {
          componentSubscriptions: [{ componentId: licenseId, quantity: calculateLicensesAmount() }],
        }
      : {};

  const preview = (customer: ReducedCustomer | {} = {}) => {
    signupService?.preview(
      {
        planVariantId: planVariantId ? planVariantId.split('&')[0] : '',
        couponCode,
        ...licenses(),
      },
      customer,
      (success: OrderWrapper) => setOrder(success.Order),
      (error: any) => {
        if (error.errorCode.indexOf('NotFound') !== -1) {
          navigate('/errors/plan-variant-invalid');
        } else {
          navigate(`/errors/order-system?planVariantId=${planVariantId}`);
        }
      },
    );
  };

  const previewWithVatId = () => {
    const country = getValues('country');
    const vatId = getValues('vatId');

    if (country && country !== 'DE' && vatId && !errors.vatId) {
      preview({ address: { country }, vatId });
    } else {
      preview();
    }
  };

  const triggerPreview = () => {
    setTriggerPreviewFlag(true);
  };

  useEffect(() => {
    if (!isValidating && triggerPreviewFlag) {
      setTriggerPreviewFlag(false);
      previewWithVatId();
    }
  }, [isValidating]);

  useEffect(() => {
    if (couponCode) {
      previewWithVatId();
    }
  }, [couponCode]);

  // load preview to display relevant information about plan
  useEffect(() => {
    if (signupService) {
      previewWithVatId();
    }
  }, [signupService]);

  // activate submit button because all preconditions are done
  useEffect(() => {
    if (signupService && paymentService) {
      setSubmitButtonActive(true);
    }
  }, [signupService, paymentService]);

  useEffect(() => {
    if (order && isMedflexPro() && !licensesAmount && !licenseId) {
      setLicenseId(process.env.REACT_APP_MEDFLEX_PRO_COMPONENT_ID || 'NOT_SET');
      setLicensesAmount(1);
    }
  }, [order]);

  useEffect(() => {
    previewWithVatId();
  }, [licensesAmount]);

  // Either returns the coupon code if it's valid or an empty string
  // Customer should be able to order even if his coupon code is invalid
  const getCouponCodeForOrder = () => (order?.Coupon?.AppliesToCart ? couponCode : '');

  const map = (checkoutInformation: CheckoutInput): [CustomerData, DirectDebit] => {
    const customerData: CustomerData = {
      firstName: checkoutInformation.firstName,
      lastName: checkoutInformation.lastName,
      emailAddress: checkoutInformation.email,
      CompanyName: checkoutInformation.practiceName,
      PhoneNumber: checkoutInformation.phone,
      vatId: checkoutInformation.vatId,
      Address: {
        Country: checkoutInformation.country,
        City: checkoutInformation.city,
        HouseNumber: checkoutInformation.houseNumber,
        Postalcode: checkoutInformation.zip,
        Street: checkoutInformation.street,
      },
    };

    const directDebit: DirectDebit = {
      bearer: `Debit:${process.env.REACT_APP_PSP}`,
      accountHolder: checkoutInformation.accountOwner,
      iban: checkoutInformation.iban,
      mandateReference: 'mandateReference',
      mandateSignatureDate: new Date().toISOString(),
      mandateText: 'mandateText',
    };

    return [customerData, directDebit];
  };

  const onSubmit: SubmitHandler<CheckoutInput> = data => {
    trackClick(false, t('CheckoutPage.Form.button'));
    setSubmitButtonLoading(true);
    const [customerData, directDebit] = map(data);

    signupService?.createOrder(
      {
        planVariantId: planVariantId || '',
        couponCode: getCouponCodeForOrder(),
        ...licenses(),
      },
      customerData,
      (orderResponse: OrderResponse) => {
        directDebit.mandateReference = orderResponse.ContractId;
        directDebit.mandateText = t('CheckoutPage.Form.Fields.PaymentMethod.Debit.details');

        signupService.paySignupInteractive(
          paymentService!,
          directDebit,
          orderResponse,
          (finalizeSuccess: FinalizeSuccess) =>
            navigate(
              `/checkout/success?orderId=${finalizeSuccess.OrderId}&grossTotalAmount=${finalizeSuccess.GrossTotal}`,
            ),
          () =>
            navigate(
              `/errors/order-system?planVariantId=${planVariantId}&email=${customerData.emailAddress}`,
            ),
        );
      },
      () =>
        navigate(
          `/errors/order-system?planVariantId=${planVariantId}&email=${customerData.emailAddress}`,
        ),
    );
  };

  return (
    <div className="lg:flex lg:flex-row lg:pb-24 lg:pt-10">
      <div className="bg-blue-500 text-white p-4 lg:hidden">
        <PlanOverview
          order={order}
          licenseAmount={licensesAmount}
          setLicenseAmount={setLicensesAmount}
        />
      </div>

      <div className="basis-2/3">
        <div className="px-4 lg:pt-0 lg:pr-16">
          <div className="text-right pb-4">{t('CheckoutPage.Form.mandatory')}</div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="mb-12">
                <CustomerInformationForm triggerPreview={triggerPreview} />
              </div>
              <div className="mb-12">
                <PaymentInformationForm />
              </div>
              <div className="lg:px-0 lg:hidden mb-12">
                <div className="bg-white lg:bg-blue-100 p-4 lg:p-8">
                  <PlanPricing order={order} licenseAmount={licensesAmount} />
                </div>
                {couponCodeActive && (
                  <div className="bg-white p-4">
                    <PlanCouponCode order={order} setCouponCode={setCouponCode} />
                  </div>
                )}
                <div className="bg-white p-4 lg:p-8">
                  <PlanAdditionalInformation order={order} />
                </div>
              </div>
              <div className="mb-8">
                <CheckboxForm />
              </div>
              <div>
                {submitButtonActive && (
                  <Button
                    className="w-full lg:w-auto mb-12"
                    isLoading={submitButtonLoading}
                    type="submit"
                  >
                    {t('CheckoutPage.Form.button')}
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <div className="basis-1/3">
        <div className="pt-4 lg:pt-0 lg:pl-10">
          <div className="lg:plan-overview-shadow hidden lg:block plan-bottom-shadow mb-10">
            <div className="bg-blue-500 text-white p-8">
              <PlanOverview
                order={order}
                licenseAmount={licensesAmount}
                setLicenseAmount={setLicensesAmount}
              />
            </div>
            <div className="bg-white p-8">
              <PlanDescription order={order} />
            </div>
            <div className="px-4 lg:px-0">
              <div className="bg-white lg:bg-blue-100 pt-4 px-4 lg:pb-8 lg:pl-8 lg:pr-8">
                <PlanPricing order={order} licenseAmount={licensesAmount} />
              </div>
              {couponCodeActive && (
                <div className="bg-white pt-6 px-4 lg:px-8">
                  <PlanCouponCode order={order} setCouponCode={setCouponCode} />
                </div>
              )}
              <div className="bg-white py-6 px-4 lg:pt-8 lg:px-8">
                <PlanAdditionalInformation order={order} />
              </div>
            </div>
          </div>

          <div className="bg-white p-4 pt-10 lg:p-8 text-center lg:text-left">
            <div className="border-b-2 border-slate-500 pb-10 lg:pb-0 lg:border-0">
              <OrderSupportInfoText />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
