const fp = require('lodash/fp');

export const getFullNameWithTFL = fp.flow(
  fp.pick(['title', 'firstName', 'lastName']),
  fp.values,
  fp.join(' '),
  fp.trim,
);
export const getFullNameWithLF = fp.flow(
  fp.pick(['lastName', 'firstName']),
  fp.values,
  fp.join(', '),
);

export const getFullNameWithFL = fp.flow(
  fp.pick(['firstName', 'lastName']),
  fp.values,
  fp.join(', '),
);
export const getWorkplaceOrTFL = p => p.workplace || getFullNameWithTFL(p);
