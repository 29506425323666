import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import * as React from 'react';

export const KINDS = {
  primar: 'primary',
  secondary: 'secondary',
  white: 'white',
};

const Spinner = ({ size = 'base', kind = 'primary', className = '', ...props }) => {
  const cn = useClassNames(
    () => [
      'inline-block',
      'w-text-150',
      'h-text-150',
      size === 'base' && 'text-base',
      size === 'xs' && 'text-xs',
      size === 'sm' && 'text-sm',
      size === 'lg' && 'text-lg',
      size === 'xl' && 'text-xl',
      size === '2xl' && 'text-2xl',
      size === '3xl' && 'text-3xl',
      size === '4xl' && 'text-4xl',
      kind === 'primary' && 'spinner-primary',
      kind === 'secondary' && 'spinner-secondary',
      kind === 'white' && 'spinner-white',
      className,
    ],
    [className],
  );
  return <span data-component="Spinner" className={cn} {...props} />;
};

Spinner.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'white']),
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl', '2xl', '3xl', '4xl']),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default Spinner;
