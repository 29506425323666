export enum PhoneStatus {
  COMPLETED = 'COMPLETED',
  UNCOMPLETED = 'UNCOMPLETED',
  CANCELED = 'CANCELED',
}

export enum PhoneStatusToInquirySettingsName {
  'UNCOMPLETED' = 'uncompletedCallsAt',
  'CANCELED' = 'canceledCallsAt',
}

export enum RequestStatus {
  OPEN = 'open',
  PROGRESS = 'progress',
  CLOSED = 'closed',
}

export enum Sources {
  PHONE = 'PHONE',
  CONTACT_PAGE = 'CONTACT_PAGE',
  MESSENGER = 'MESSENGER',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
}

export enum RequestValueKeys {
  PATIENT_INSURANCE_TYPE_OR_MEDFLEX = 'patientInsuranceTypeOrMedflex',
  APPOINTMENT_ACTION = 'appointmentAction',
  APPOINTMENT_REASON = 'appointmentReason',
  APPOINTMENT_TYPE = 'appointmentType',
  SYMPTOM_DESCRIPTION = 'symptomsDescription',
  SYMPTOM_DURATION = 'symptomsDuration',
  EXISTING_APPOINTMENT_DATE_TIME = 'existingAppointmentDateTime',
  APPOINTMENT_DATE_TYPE = 'appointmentDateType',
  APPOINTMENT_AVAILABILITY = 'appointmentAvailability',
  DIAGNOSIS_DETAILS = 'diagnosisDetails',
  DIAGNOSIS_REQUEST_TYPE = 'diagnosisRequestType',
  AFTER_CARE_DETAILS = 'afterCareDetails',
  FOLLOW_UP_PRESCRIPTION = 'followUpPrescription',
  GENERAL_INQUIRY_DETAILS = 'generalInquiryDetails',
  REFERRAL_REASON = 'referralReason',
  DOCUMENTS_DELIVERY = 'documentsDelivery',
  CHECK_UP_DETAILS = 'checkUpDetails',
  ADDITIONAL_COMMENT = 'additionalComment',
  FILES = 'files',
  PHONE_TRANSCRIPT = 'phoneTranscript',
  CALL = 'call',
  PHONE_AUDIO_RECORDING_URL = 'phoneAudioRecordingUrl',
  DOCUMENTS = 'documents',
  REPRESENTED_PERSON = 'representedPerson',
}

export enum RequestTypes {
  APPOINTMENT = 'appointment',
  FINDINGS_INQUIRY = 'findings_inquiry',
  REFERRAL = 'referral',
  GENERAL_INQUIRY = 'general_inquiry',
  REQUEST_FOLLOW_UP_PRESCRIPTION = 'request_follow_up_prescription',
  CONNECT_TO_PHYSICIAN = 'connect_to_physician',
  PHONE = 'phone',
  DOCUMENT = 'document',
}

export enum RequestAppointmentActions {
  NEW = 'new',
  POSTPONE = 'postpone',
  CANCEL = 'cancel',
}

export enum AppointmentDateTypes {
  SUGGESTED_DATE = 'suggested_date',
  NEXT_POSSIBLE_DATE = 'next_possible_date',
}

export enum AppointmentReasons {
  ACUTE_SYMPTOMS = 'acute_symptoms',
  GENERAL_INQUIRY = 'general_inquiry',
  DISCUSSION_OF_RESULTS = 'discussion_of_results',
  POST_SURGERY_APPOINTMENT = 'post_surgery_appointment',
  PRE_SURGERY_APPOINTMENT = 'pre_surgery_appointment',
}

export enum AppointmentTypes {
  VIDEO_CONSULTATION = 'video_consultation',
  ON_SITE_APPOINTMENT = 'on_site_appointment',
}

export enum DocumentsDelivery {
  COLLECT = 'collect',
  POSTAL = 'postal',
}

export enum PatientInsuranceTypeOrMedflex {
  PRIVATE = 'private',
  PUBLIC = 'public',
  MEDFLEX = 'medflex',
}

export enum RequestTimeRanges {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  OLDER_THAN_30_DAYS = 'older_than_30_days',
}

export enum RequestSortOrders {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  IMPORTANT = 'important',
}

export enum DiagnosisRequestTypes {
  COMMUNICATION_OF_RESULTS = 'communication_of_results',
  TRANSMISSION_OF_DOCUMENTS = 'transmission_of_documents',
}

export enum SettingsNameToRequestType {
  'appointment' = RequestTypes.APPOINTMENT,
  'findingsInquiry' = RequestTypes.FINDINGS_INQUIRY,
  'referral' = RequestTypes.REFERRAL,
  'generalInquiry' = RequestTypes.GENERAL_INQUIRY,
  'requestFollowUpPrescription' = RequestTypes.REQUEST_FOLLOW_UP_PRESCRIPTION,
  'connectToPhysician' = RequestTypes.CONNECT_TO_PHYSICIAN,
}

export const PamRequestActivityReferenceType = 'pamRequest';
export enum PamRequestActivityTypes {
  CREATED_PAM_REQUEST = 'created_pam_request',
  MODIFIED_PAM_REQUEST = 'modified_pam_request',
}
