import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import * as React from 'react';

const Icon = ({ size, width, height, className, children, ...props }) => {
  const cn = useClassNames(
    () => [
      'inline-block',
      !width && size === '3' && 'w-3',
      !height && size === '3' && 'h-3',
      !width && size === '4' && 'w-4',
      !height && size === '4' && 'h-4',
      !width && size === '5' && 'w-5',
      !height && size === '5' && 'h-5',
      !width && size === '7' && 'w-7',
      !height && size === '7' && 'h-7',
      !width && size === '8' && 'w-8',
      !height && size === '8' && 'h-8',
      !width && size === '10' && 'w-10',
      !height && size === '10' && 'h-10',
      !width && size === '12' && 'w-12',
      !height && size === '12' && 'h-12',
      !height && size === '16' && 'h-16',
      !width && size === '16' && 'w-16',
      !height && size === '20' && 'h-20',
      !width && size === '20' && 'w-20',
      !width && size === 'text' && 'w-text',
      !height && size === 'text' && 'h-text',
      className,
      !width && (!size || size === '6') && 'w-6',
      !height && (!size || size === '6') && 'h-6',
    ],
    [className, size],
  );

  const style = {};
  if (width) style.width = width;
  if (height) style.height = height;

  return (
    <span data-component="Icon" className={cn} style={style} {...props}>
      {children}
    </span>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(['3', '4', '5', '6', '7', '8', '10', '12', '16', '20', 'text']),
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default Icon;
