import { getWorkplaceOrTFL } from './users.utils';

type Inviter = {
  title?: string;
  workplace?: string;
  firstName: string;
  lastName: string;
};

export const getIFunnelInvitationMessage = (inviter: Inviter, invitationLink: string) => {
  return [
    getWorkplaceOrTFL(inviter),
    `ist für Sie einfach digital erreichbar. Verbinden Sie sich jetzt kostenlos, um Ihre Anliegen schnell & sicher zu übermitteln: ${invitationLink}`,
  ].join(' ');
};
export const getInvitationMessage = (inviter: Inviter, invitationLink: string) => {
  return [
    'Sie haben bei',
    getWorkplaceOrTFL(inviter),
    `angerufen. Registrieren Sie sich hier, damit Ihre Praxis Sie erreichen kann: ${invitationLink}`,
  ].join(' ');
};
