import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const InputCheckbox = forwardRef(({ className, state, disabled, name, ...props }, ref) => {
  const cn = useClassNames(
    () => [
      'form-checkbox',
      'rounded',
      'border-1',
      !disabled && state === 'invalid' && 'border-red-600',
      !disabled && state === 'invalid' && 'text-red-600',
      !disabled && state === 'valid' && 'border-green-500',
      !disabled && state === 'valid' && 'text-green-500',
      className,
    ],
    [disabled, className, state],
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <input name={name} type="checkbox" className={cn} disabled={disabled} ref={ref} {...props} />
  );
});

InputCheckbox.displayName = 'InputCheckBox';

InputCheckbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.oneOf(['default', 'invalid', 'valid']),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};

InputCheckbox.defaultProps = {
  name: null,
  disabled: false,
  state: 'default',
  className: null,
};

export default InputCheckbox;
