import Button from '@medflex/components/stories/Button';
import FormErrorMessage from '@medflex/components/stories/FormErrorMessage/FormErrorMessage';
import IconTag from '@medflex/components/stories/icons/Tag';
import InputField from '@medflex/components/stories/InputField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/billwerk';

type PlanCouponCodeProps = {
  setCouponCode: (couponCode: string) => void;
  order: Order | undefined;
};

const PlanCouponCode = ({ setCouponCode, order }: PlanCouponCodeProps) => {
  const { t } = useTranslation('checkout');

  const [applyButtonLoading, setApplyButtonLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [tmpCouponCode, setTmpCouponCode] = useState('');

  const onSubmit = (event: any) => {
    event.preventDefault();

    if (tmpCouponCode !== '') {
      setApplyButtonLoading(true);
      setCouponCode(tmpCouponCode);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => setApplyButtonLoading(false), [order]);

  return (
    <div>
      <div className="mb-3">{t('CheckoutPage.Plan.CouponCode.text')}</div>
      <div>
        <InputField
          afterInput={
            <Button
              isLoading={applyButtonLoading}
              onClick={(event: any) => onSubmit(event)}
              type="submit"
            >
              {t('CheckoutPage.Plan.CouponCode.button')}
            </Button>
          }
          defaultValue=""
          placeholder={t('CheckoutPage.Plan.CouponCode.placeholder')}
          state={error ? 'invalid' : 'default'}
          onChange={(event: any) => setTmpCouponCode(event.target.value)}
          name="couponCode"
        />
      </div>
      {!order?.Coupon && error && (
        <FormErrorMessage message={t('CheckoutPage.Plan.CouponCode.invalid')} />
      )}
      {order?.Coupon &&
        (order?.Coupon?.AppliesToCart ? (
          <p className="text-green-500 pt-3 pl-3">
            <IconTag size="4" />
            <strong className="ml-3">{t('CheckoutPage.Plan.CouponCode.valid')}</strong>
          </p>
        ) : (
          <FormErrorMessage message={t('CheckoutPage.Plan.CouponCode.invalid')} />
        ))}
    </div>
  );
};

export default PlanCouponCode;
