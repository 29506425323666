// eslint-disable-next-line import/prefer-default-export
export enum TextSnippetTags {
  PAM_REQUEST_QUICK_REPLY = 'pamRequestQuickReply',
  APPOINTMENT = 'appointment',
  GENERAL_INQUIRY = 'general_inquiry',
  FOLLOW_UP_PRESCRIPTION = 'request_follow_up_prescription',
  REFERRAL = 'referral',
  FINDINGS_INQUIRY = 'findings_inquiry',
  PHONE = 'phone',
}
