import OrderSupportInfoText from '../OrderSupportInfoText';

type ErrorPageProps = {
  title: string;
  children: React.ReactNode;
};

// Using React.FC to get "children"
const ErrorPage: React.FC<ErrorPageProps> = ({ title, children }: ErrorPageProps) => (
  <div className="lg:flex lg:flex-row p-4 lg:p-0">
    <div className="basis-7/12">
      <div className="mb-4 text-xl pt-12">
        <h1>
          <strong>{title}</strong>
        </h1>
      </div>
      <div className="mb-12">{children}</div>
      <div className="lg:flex lg:flex-row">
        <div className="bg-white p-4 basis-8/12">
          <OrderSupportInfoText />
        </div>
      </div>
    </div>
    <div className="basis-1/12 mb-8 lg:mb-0" />
    <div className="basis-4/12">
      <img src="/error_page.svg" className="mx-auto" alt="" />
    </div>
  </div>
);

export default ErrorPage;
