import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import * as React from 'react';

const Logo = ({ size, className, ...props }) => {
  const cn = useClassNames(
    () => [
      size === '6' && 'h-6',
      size === '8' && 'h-8',
      size === '10' && 'h-10',
      (!size || size === '12') && 'h-12',
      size === '16' && 'h-16',
      size === '20' && 'h-20',
      size === '24' && 'h-24',
      size === '32' && 'h-32',
      'select-none',
      className,
    ],
    [className],
  );
  return (
    <svg className={cn} data-component="Logo" height="100%" viewBox="0 0 264.37 95.66" {...props}>
      <path
        d="M108.69,42h3.19l.33,3.29h.14c2-2.16,4.36-3.85,7.08-3.85,3.48,0,5.35,1.64,6.29,4.36,2.39-2.58,4.74-4.36,7.51-4.36,4.69,0,6.94,3.1,6.94,8.91V64.76h-3.85V50.83c0-4.27-1.36-6.1-4.22-6.1-1.78,0-3.61,1.17-5.72,3.52V64.76h-3.85V50.83c0-4.27-1.36-6.1-4.27-6.1-1.69,0-3.61,1.17-5.72,3.52V64.76h-3.85Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M144.72,53.41c0-7.46,5-12,10.22-12,5.82,0,9.1,4.18,9.1,10.7a15.44,15.44,0,0,1-.14,2.2H148.52c.28,4.83,3.23,7.93,7.59,7.93a10.38,10.38,0,0,0,5.68-1.78L163.15,63a13.54,13.54,0,0,1-7.5,2.34C149.55,65.32,144.72,60.87,144.72,53.41Zm15.94-1.88c0-4.59-2.06-7.08-5.63-7.08-3.18,0-6.09,2.58-6.56,7.08Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M166.85,53.41c0-7.41,4.69-12,9.9-12,2.63,0,4.36,1,6.38,2.63l-.19-3.89V31.36h3.9v33.4h-3.19l-.33-2.67h-.14a10.32,10.32,0,0,1-6.9,3.23C170.56,65.32,166.85,61,166.85,53.41Zm16.09,5.53V47a7.91,7.91,0,0,0-5.53-2.39c-3.66,0-6.57,3.52-6.57,8.72,0,5.44,2.3,8.73,6.29,8.73C179.24,62.09,181.07,61.06,182.94,58.94Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M195,46h-3.1V42l3.1-.23V39.34c0-4.79,2.2-8.26,7.65-8.26a11.73,11.73,0,0,1,4.22.8l-1,4a6.41,6.41,0,0,0-2.53-.56c-1.87,0-2.91,1.22-2.91,3.89v2.49h4.51V46h-4.51V64.76H195Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M210.05,58.85V31.65h5.4V59.13c0,1.32.61,1.78,1.17,1.78a3.34,3.34,0,0,0,.84-.09l.71,4a7.45,7.45,0,0,1-2.86.46C211.46,65.32,210.05,62.84,210.05,58.85Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M221.21,53.27c0-7.51,5.21-12.1,10.61-12.1,6.23,0,9.52,4.5,9.52,10.93a12.48,12.48,0,0,1-.24,2.62H226.47c.47,4.08,3.09,6.43,6.8,6.43a9.63,9.63,0,0,0,5.35-1.69l1.83,3.38a14.6,14.6,0,0,1-7.88,2.48C226.19,65.32,221.21,60.87,221.21,53.27Zm15.44-2.21c0-3.56-1.55-5.72-4.69-5.72-2.72,0-5.07,2-5.54,5.72Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M250.44,52.75l-6.85-11h5.82L252,46.09c.66,1.27,1.41,2.63,2.12,3.9h.18c.57-1.27,1.22-2.63,1.79-3.9l2.2-4.36h5.58l-6.8,11.63,7.32,11.4h-5.82l-2.81-4.55c-.75-1.36-1.55-2.77-2.35-4.08h-.19c-.65,1.31-1.36,2.67-2,4.08l-2.44,4.55h-5.63Z"
        style={{ fill: '#003e79' }}
      />
      <path
        d="M37.2,0A37.21,37.21,0,0,0,0,37.2V95.66L21.26,74.41H37.2A37.21,37.21,0,0,0,74.41,37.2V0Z"
        style={{ fill: '#0052a1' }}
      />
      <path
        d="M64.36,21.54A26.49,26.49,0,0,1,90.85,48V89.66L75.72,74.52H64.36A26.49,26.49,0,0,1,37.87,48V21.54Z"
        style={{ fill: '#469df1', opacity: 0.7 }}
      />
    </svg>
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['6', '8', '10', '12', '16', '20', '24', '32']),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default Logo;
