import IconTag from '@medflex/components/stories/icons/Tag';
import { useTranslation } from 'react-i18next';
import { formatCurrencyAmount } from '../../utils/FormatUtils';
import { Order } from '../../types/billwerk';

type PlanPricingProps = {
  order: Order | undefined;
  licenseAmount: number | undefined;
};

const PlanPricing = ({ order, licenseAmount }: PlanPricingProps) => {
  const { t } = useTranslation('checkout');

  return (
    <div>
      <div>
        <strong>{t('CheckoutPage.Plan.summary')}</strong>
      </div>
      {licenseAmount && (
        <div>
          <em>{t('CheckoutPage.Plan.licensePricingInfo', { count: licenseAmount })}</em>
        </div>
      )}
      <div className="flex flex-row">
        <div className="basis-8/12">{t('CheckoutPage.Plan.netMonth')}</div>
        <div className="basis-4/12 text-right">
          {order?.Coupon?.AppliesToCart && <IconTag size="4" className="text-green-500" />}
          <span className="ml-3">
            {formatCurrencyAmount(order?.Currency, (order?.Total || 0) / 12)}
          </span>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-8/12">{t('CheckoutPage.Plan.period')}</div>
        <div className="basis-4/12 text-right">
          {t(`CheckoutPage.Plan.Unit.${order?.RecurringFee.FeePeriod.Unit.toLowerCase()}`, {
            count: order?.RecurringFee.FeePeriod.Quantity,
          })}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-8/12">{t('CheckoutPage.Plan.netYear')}</div>
        <div className="basis-4/12 text-right">
          {formatCurrencyAmount(order?.Currency, order?.Total || 0)}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-8/12">
          {t('CheckoutPage.Plan.taxExcluded')} ({order?.RecurringFee?.VatPercentage}%):
        </div>
        <div className="basis-4/12 text-right">
          {formatCurrencyAmount(order?.Currency, order?.TotalVat || 0)}
        </div>
      </div>
      <div className="border border-blue-500 bg-blue-500 my-2" />
      <div className="flex flex-row">
        <div className="basis-8/12">
          <strong>{t('CheckoutPage.Plan.total')}</strong>
        </div>
        <div className="basis-4/12 text-right">
          {order?.Coupon?.AppliesToCart && <IconTag size="4" className="text-green-500" />}
          <strong className="ml-3">
            {formatCurrencyAmount(order?.Currency, order?.TotalGross || 0)}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default PlanPricing;
