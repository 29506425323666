import { countries } from '@medflex/commons';
import { EMAIL_REGEX } from '@medflex/shared-utils';
import DropDown from '@medflex/components/stories/DropDown/DropDown';
import InputField from '@medflex/components/stories/InputField';
import FormErrorMessage from '@medflex/components/stories/FormErrorMessage/FormErrorMessage';
import { useSubscriptionJS } from 'hooks/useSubscriptionJs';
import { Fragment, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionJS } from 'types/billwerk';
import PhoneNumberField from 'components/PhoneNumberField';
import FormInput from './FormInput';

export interface CustomerInformation {
  firstName: string;
  lastName: string;
  practiceName: string;
  email: string;
  phone: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  country: string;
  vatId: string;
}

type CustomerInformationFormProps = {
  triggerPreview: () => void;
};

const CustomerInformationForm = ({ triggerPreview }: CustomerInformationFormProps) => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const [subscriptionJs, setSubscriptionJS] = useState<SubscriptionJS | undefined>(undefined);

  useSubscriptionJS(setSubscriptionJS);

  const { t } = useTranslation('checkout');

  const requiredMessage: string = t('CheckoutPage.Form.Errors.required');
  const emailRequiredMessage: string = t('global:validation.messages.email');

  const rearrangedCountryList = useMemo(() => {
    const { DE, AT, CH, ...restOfTheWorld } = countries as { [key: string]: string };
    return {
      DE,
      AT,
      CH,
      ...restOfTheWorld,
    };
  }, []);

  const validateVatId = (vatId: string) => {
    // vatId is an optional field
    // only validate vatId if it's set
    if (vatId === '') {
      return true;
    }

    if (subscriptionJs) {
      return subscriptionJs.validateVatId(vatId) ? true : t('CheckoutPage.Form.Errors.vatId');
    }

    // Fallback: Ignore vatId validation if SubscriptionJS isn't available
    // will be checked in billwerk before order is accepted
    return true;
  };

  const evaluateAdditionText = () =>
    getValues('country') !== 'DE' ? t('CheckoutPage.Form.Fields.vatIdInfoText') : undefined;

  return (
    <div>
      <div className="mb-4">
        <strong>{t('CheckoutPage.Form.invoiceData')}</strong>
      </div>
      <FormInput
        name="firstName"
        text={t('CheckoutPage.Form.Fields.firstName')}
        errors={errors}
        register={register}
        options={{ required: requiredMessage }}
      />
      <FormInput
        name="lastName"
        text={t('CheckoutPage.Form.Fields.lastName')}
        errors={errors}
        register={register}
        options={{ required: requiredMessage }}
      />
      <FormInput
        name="practiceName"
        text={t('CheckoutPage.Form.Fields.workplace')}
        errors={errors}
        register={register}
        options={{ required: requiredMessage }}
      />
      <Controller
        name="email"
        defaultValue={searchParams.get('email') || ''}
        control={control}
        rules={{
          required: emailRequiredMessage,
          pattern: {
            value: EMAIL_REGEX,
            message: t('global:validation.messages.email'),
          },
        }}
        render={({ field }) => (
          <div className="pb-4">
            <label htmlFor={field.name}>{t('CheckoutPage.Form.Fields.email')} *</label>
            <br />
            <InputField
              id={field.name}
              state={errors[field.name] ? 'invalid' : 'default'}
              disabled={Boolean(searchParams.get('email')) && !errors[field.name]}
              {...field}
            />
            {errors[field.name] && typeof errors[field.name]?.message === 'string' && (
              <FormErrorMessage message={errors[field.name]?.message as string} />
            )}
          </div>
        )}
      />
      <Controller
        name="phone"
        defaultValue="+49"
        control={control}
        render={({ field: { name } }) => (
          <div className="pb-4">
            <label htmlFor="phone">{t('CheckoutPage.Form.Fields.phone')}</label>
            <br />
            <PhoneNumberField name={name} control={control} type="landline" />
            {errors[name] && typeof errors[name]?.message === 'string' && (
              <FormErrorMessage message={errors[name]?.message as string} />
            )}
          </div>
        )}
      />

      <div className="lg:flex lg:flex-row">
        <div className="basis-3/4 lg:pr-5">
          <FormInput
            name="street"
            text={t('CheckoutPage.Form.Fields.street')}
            errors={errors}
            register={register}
            options={{ required: requiredMessage }}
          />
        </div>
        <div className="basis-1/4">
          <FormInput
            name="houseNumber"
            text={t('CheckoutPage.Form.Fields.houseNumber')}
            errors={errors}
            register={register}
            options={{ required: requiredMessage }}
          />
        </div>
      </div>
      <div className="lg:flex lg:flex-row">
        <div className="basis-3 lg:pr-5">
          <FormInput
            name="zip"
            text={t('CheckoutPage.Form.Fields.zip')}
            errors={errors}
            register={register}
            options={{ required: requiredMessage }}
          />
        </div>
        <div className="basis-full">
          <FormInput
            name="city"
            text={t('CheckoutPage.Form.Fields.city')}
            errors={errors}
            register={register}
            options={{ required: requiredMessage }}
          />
        </div>
      </div>
      <div className="pb-4">
        <label htmlFor="country">{t('CheckoutPage.Form.Fields.country')} *</label>
        <br />
        <DropDown
          id="country"
          {...register('country', { required: requiredMessage })}
          state={errors.country ? 'invalid' : 'default'}
          initialValue="DE"
          onBlur={triggerPreview}
        >
          <option disabled>---------</option>
          {Object.entries(rearrangedCountryList).map(([key, value]) => (
            <Fragment key={`country#${key}`}>
              <option value={key}>{value}</option>
              {key === 'CH' && <option disabled>---------</option>}
            </Fragment>
          ))}
        </DropDown>
      </div>
      <FormInput
        name="vatId"
        text={t('CheckoutPage.Form.Fields.vatId')}
        errors={errors}
        register={register}
        options={{ validate: validateVatId, onBlur: triggerPreview }}
        additionalText={evaluateAdditionText()}
      />
    </div>
  );
};

export default CustomerInformationForm;
