import IconCheckmarkOutline from '@medflex/components/stories/icons/CheckmarkOutline';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/billwerk';

type PlanDescriptionProps = {
  order: Order | undefined;
};

const PlanDescription = ({ order }: PlanDescriptionProps) => {
  const { t } = useTranslation('checkout');

  return (
    <div>
      <div className="mb-4">
        <strong>{t('CheckoutPage.Plan.description')}</strong>
      </div>
      <div>
        {order?.RecurringFee.Description.split(',').map((description, i) => (
          <div className="flex flex-row mb-4" key={i}>
            <div>
              <IconCheckmarkOutline className="text-green-500" size="6" />
            </div>
            <div className="ml-4">{description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanDescription;
