import { useTranslation } from 'react-i18next';

import Button from '@medflex/components/stories/Button';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { trackClick, trackEcommerceOrder } from '../utils/tracking';

const OrderSuccessPage = () => {
  const { t } = useTranslation('checkout');

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const grossTotalAmount = searchParams.get('grossTotalAmount');

  useEffect(() => {
    if (orderId && grossTotalAmount) {
      trackEcommerceOrder(true, orderId, parseFloat(grossTotalAmount));
    }
  }, []);

  const goToProfile = () => {
    trackClick(true, t('CheckoutPage.Success.Overview.linkToProfile'));

    window.location.href = process.env.REACT_APP_LINK_TO_PROFILE || '';
  };

  return (
    <div className="pt-16">
      <div className="flex items-center justify-center mb-12">
        <img src="/medflex_success.svg" className="inline" alt="" />
        <span className="ml-5 text-4xl">
          <strong>{t('CheckoutPage.Success.Overview.text')}</strong>
        </span>
      </div>
      <div className="flex items-center justify-center mb-16">
        <Button className="block" onClick={() => goToProfile()}>
          {t('CheckoutPage.Success.Overview.linkToProfile')}
        </Button>
      </div>
      <div className="mb-3">
        <strong>{t('CheckoutPage.Success.Overview.nextSteps')}</strong>
      </div>
      <div className="lg:flex lg:flex-row lg:space-x-6 lg:space-y-0 space-y-6">
        <div className="basis-1/3 bg-white p-12">
          <div className="flex flex-row justify-center items-center mb-4">
            <img src="/medflex_award.svg" alt="" />
          </div>
          <strong className="block w-full text-center mb-3">
            {t('CheckoutPage.Success.Functionalities.headline')}
          </strong>
          <ul className="list-disc ml-5">
            <li>{t('CheckoutPage.Success.Functionalities.bulletpoint1')}</li>
          </ul>
        </div>
        <div className="basis-1/3 bg-white p-12">
          <div className="flex flex-row justify-center items-center mb-4">
            <img src="/medflex_send.svg" alt="" />
          </div>
          <strong className="block w-full text-center mb-3">
            {t('CheckoutPage.Success.Invoice.headline')}
          </strong>
          <ul className="list-disc ml-5">
            <li>{t('CheckoutPage.Success.Invoice.bulletpoint1')}</li>
            <li>{t('CheckoutPage.Success.Invoice.bulletpoint2')}</li>
          </ul>
        </div>
        <div className="basis-1/3 bg-white p-12">
          <div className="flex flex-row justify-center items-center mb-4">
            <img src="/medflex_support.svg" alt="" />
          </div>
          <strong className="block w-full text-center mb-3">
            {t('CheckoutPage.Success.Order.headline')}
          </strong>
          <div>
            {t('CheckoutPage.SupportInfo.openingHours')}
            <ul className="list-disc ml-5">
              <li>
                {t('CheckoutPage.SupportInfo.phone')} {t('CheckoutPage.SupportInfo.phoneNumber')}
              </li>
              <li>
                {t('CheckoutPage.SupportInfo.email')} {t('CheckoutPage.SupportInfo.emailaddress')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessPage;
