import DropDown from '@medflex/components/stories/DropDown/DropDown';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/billwerk';

type PlanOverviewProps = {
  order: Order | undefined;
  licenseAmount: number | undefined;
  setLicenseAmount: (licenseAmount: number | undefined) => void;
};

const PlanOverview = ({ order, licenseAmount, setLicenseAmount }: PlanOverviewProps) => {
  const maxLicenses = Number(process.env.REACT_APP_MEDFLEX_PRO_MAX_LICENSE_AMOUNT) || 1;
  const licensesAmounts = [...Array(maxLicenses).keys()].map(count => count + 1);

  const { t } = useTranslation('checkout');

  const formatAmount = (amount: number) =>
    order?.Currency
      ? amount.toLocaleString(undefined, { style: 'currency', currency: order?.Currency })
      : amount;

  const onLicenseAmountChange = (event: React.FormEvent<HTMLInputElement>) => {
    setLicenseAmount(+event.currentTarget.value);
  };

  const findPricePerLicense = () =>
    licenseAmount ? order?.RecurringFee.LineItems[0]?.TotalNet || 0 : order?.Total || 0;

  return (
    <div>
      <div className="text-sm">{t('CheckoutPage.Plan.order')}</div>
      <div className="text-2xl">{order?.RecurringFee.Name}</div>
      <div className="mb-4">
        <span className="text-lg">
          {t('CheckoutPage.Plan.Overview.perLicense', {
            amount: formatAmount(findPricePerLicense() / 12),
          })}{' '}
          / {t('CheckoutPage.Plan.Unit.month')}
        </span>
        <span className="text-xs align-top pl-2">{t('CheckoutPage.Plan.taxExcluded')}</span>
      </div>
      {licenseAmount && (
        <div className="lg:flex lg:flex-row">
          <div className="basis-1/2">
            <p className="text-sm">{t('CheckoutPage.Plan.Overview.amount')}</p>
            <div className="text-slate-900">
              <DropDown
                options={licensesAmounts.map(it => it.toString())}
                optionLabels={licensesAmounts.map(count =>
                  t('CheckoutPage.Plan.Overview.license', { count }),
                )}
                onChange={onLicenseAmountChange}
              />
            </div>
          </div>
          <div className="basis-1/2 flex items-center">
            <p className="ml-3 text-xs">
              <a
                href={t('CheckoutPage.Plan.Overview.helpUrl')}
                target="_blank"
                className="text-white underline"
                rel="noreferrer"
              >
                {t('CheckoutPage.Plan.Overview.help')}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanOverview;
