// eslint-disable-next-line import/prefer-default-export
export enum MessageTypes {
  APPOINTMENT = 'appointment',
  FINDINGS = 'findings',
  MULTICAST = 'multicast',
  PRESCRIPTION = 'prescription',
  WELCOME = 'welcome',
  PATIENT = 'patient',
  SYSTEM = 'system',
  DEMO = 'demo',
  PAM = 'pam',
}
