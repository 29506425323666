const useMatomoWithCustomDimensions = (isCustomer: boolean, f: (paq: any) => void) => {
  // eslint-disable-next-line no-underscore-dangle
  const paq = window._paq || [];

  paq.push(['setCustomDimension', 1, 'physician']); // user type
  paq.push(['setCustomDimension', 2, '0.0.1']); // version
  paq.push(['setCustomDimension', 3, String(isCustomer)]); // isCustomer

  f(paq);
};

export const trackEcommerceView = (
  isCustomer: boolean,
  planVariantId: string,
  productName: string,
  netPrice: number,
) => {
  useMatomoWithCustomDimensions(isCustomer, paq => {
    paq.push([
      'setEcommerceView',
      planVariantId, // (Required) productSKU
      productName, // (Optional) productName
      '', // (Optional) categoryName //Dont needed
      netPrice, // (Optional) price
    ]);

    paq.push(['trackPageView']);
  });
};

export const trackEcommerceOrder = (
  isCustomer: boolean,
  orderId: string,
  grossTotalAmount: number,
) => {
  useMatomoWithCustomDimensions(isCustomer, paq => {
    paq.push([
      'trackEcommerceOrder',
      orderId, // (Required) orderId
      grossTotalAmount, // (Required) grandTotal (revenue)
    ]);

    paq.push(['trackPageView']);
  });
};

export const trackClick = (isCustomer: boolean, text: string) => {
  useMatomoWithCustomDimensions(isCustomer, paq => {
    paq.push(['trackEvent', 'ecommerce', 'click | button ', text]);
  });
};
