import FormErrorMessage from '@medflex/components/stories/FormErrorMessage/FormErrorMessage';
import InputField from '@medflex/components/stories/InputField';
import { FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';

type FormInputProps = {
  name: string;
  text: string;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  disabled?: boolean;
  options?: RegisterOptions<FieldValues, string> | undefined;
  additionalText?: string | undefined;
};

const FormInput = ({
  name,
  register,
  errors,
  text,
  disabled,
  additionalText,
  options,
}: FormInputProps) => (
  <div className="pb-4">
    <label htmlFor={name}>
      {text} {options?.required && '*'}
    </label>
    <br />
    <InputField
      {...register(name, options)}
      defaultValue={options?.value || ''}
      id={name}
      state={errors[name] ? 'invalid' : 'default'}
      disabled={disabled}
    />
    {errors[name] && typeof errors[name]?.message === 'string' && (
      <FormErrorMessage message={errors[name]?.message as string} />
    )}
    {additionalText && (
      <div>
        <div className="mb-2" />
        <p className="text-sm text-slate-700">{additionalText}</p>
      </div>
    )}
  </div>
);

export default FormInput;
