export enum PhoneNumberTags {
  MOBILE = 'MOBILE',
  LANDLINE = 'FIXED_LINE',
  OTHER = 'OTHER',
}

export enum PhoneNumberTypes {
  LANDLINE = 0,
  MOBILE = 1,
}

export interface PhoneNumber {
  number: string;
  tag: PhoneNumberTags;
}
