import { useEffect, useState } from 'react';
import { SubscriptionJS } from '../types/billwerk';

export function useSubscriptionJS(callback: (subscriptionJS: SubscriptionJS) => void): void {
  const [mounted, setMounted] = useState(false);
  const [lodingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const subscriptionJsUrl = process.env.REACT_APP_SUBSCRIPTION_JS_URL ?? 'NOT_SET';

    if (window.SubscriptionJS) {
      // Subscription JS is already loaded
      setLoadingComplete(true);
      return () => {};
    }

    const script = document.createElement('script');
    script.src = subscriptionJsUrl;
    script.async = true;

    const scriptLoaded = () => setLoadingComplete(true);
    script.addEventListener('load', scriptLoaded, false);

    document.body.appendChild(script);

    return () => {
      // only remove event listener because we never want to remove the SubscriptionJS
      script.removeEventListener('load', scriptLoaded);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      callback(window.SubscriptionJS);
    } else {
      setMounted(true);
    }
  }, [lodingComplete]);
}
