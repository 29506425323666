import _ from 'lodash/fp';

type TResponse<T> = {
  data: {
    type: string;
    id: string | undefined;
    attributes: T;
  }[];
};

export const omitFieldsFromList = <L extends Object, F extends ReadonlyArray<keyof L>>(
  list: L[],
  fields: F,
) => _.map(_.omit(fields))(list) as Omit<L, F[number]>[];

export const buildListResponse = <T extends { id?: string }>(type: string, items: T[] = []) =>
  items.reduce(
    (resObj: TResponse<T>, item) => {
      resObj.data.push({
        type,
        id: item.id,
        attributes: item,
      });
      return resObj;
    },
    { data: [] },
  );
