import useClassNames from '@medflex/hooks/useClassNames';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { forwardRef } from 'react';

const LINK_BASE_CLASSES = [
  'inline-flex',
  'items-center',
  'text-blue-500',
  'hover:underline',
  'leading-none',
  'focus:outline-none',
  'select-none',
];

const BUTTON_BASE_CLASSES = [
  'inline-flex',
  'items-center',
  'justify-center',
  'overflow-hidden',
  'leading-none',
  'font-semibold',
  'border',
  'border-solid',
  'focus:outline-none',
  'select-none',
];

const PRIMARY_COLORS =
  'text-white bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700';

const PRIMARY_OUTLINE_COLORS =
  'text-blue-500 hover:text-blue-700 bg-transparent border-blue-500 hover:border-blue-700';

const PRIMARY_GHOST_COLORS =
  'text-blue-500 hover:text-blue-700 bg-transparent hover:bg-slate-100 border-transparent';

const PRIMARY_COLORS_ACTIVE = 'text-white bg-blue-700 border-blue-700';
const PRIMARY_OUTLINE_COLORS_ACTIVE = 'text-blue-700 bg-transparent border-blue-700';
const PRIMARY_GHOST_COLORS_ACTIVE = 'text-blue-700 bg-slate-100 border-transparent';

const SECONDARY_COLORS =
  'text-white bg-slate-400 hover:bg-slate-600 border-slate-400 hover:border-slate-600';

const SECONDARY_OUTLINE_COLORS =
  'text-slate-500 hover:text-slate-600 bg-transparent border-slate-400 hover:border-slate-500';

const SECONDARY_GHOST_COLORS =
  'text-slate-400 hover:text-slate-600 bg-transparent hover:bg-slate-100 border-transparent';

const SECONDARY_COLORS_ACTIVE = 'text-white bg-slate-600 border-slate-600';
const SECONDARY_OUTLINE_COLORS_ACTIVE = 'text-slate-600 bg-transparent border-slate-500';
const SECONDARY_GHOST_COLORS_ACTIVE =
  'text-slate-600 bg-transparent bg-slate-100 border-transparent';

const DISABLED_COLORS =
  'font-semibold text-slate-400 hover:text-slate-400 bg-slate-300 hover:bg-slate-300 border-transparent hover:border-transparent cursor-not-allowed';
const DISABLED_COLORS_LINK = 'font-semibold text-slate-400 hover:text-slate-400 cursor-not-allowed';

const SHAPE_DEFAULT_SIZE_DEFAULT = 'h-12 px-12 rounded';
const SHAPE_SQUARE_SIZE_DEFAULT = 'px-0 h-12 w-12 rounded';
const SHAPE_CIRCLE_SIZE_DEFAULT = 'px-0 h-12 w-12 rounded-full';

const SHAPE_DEFAULT_SIZE_LG = 'h-16 px-12 text-lg lg:text-xl rounded';
const SHAPE_DEFAULT_SIZE_AUTO = 'px-12 text-lg lg:text-xl rounded';

const SHAPE_DEFAULT_SIZE_SM = 'h-10 px-10 text-sm rounded-sm';
const SHAPE_SQUARE_SIZE_SM = 'px-0 h-10 w-10 text-sm rounded-sm';
const SHAPE_CIRCLE_SIZE_SM = 'px-0 h-10 w-10 text-sm rounded-full';

const SHAPE_DEFAULT_SIZE_XS = 'h-8 px-8 text-xs rounded-sm';
const SHAPE_SQUARE_SIZE_XS = 'px-0 h-8 w-8 text-xs rounded-sm';
const SHAPE_CIRCLE_SIZE_XS = 'px-0 h-8 w-8 text-xs rounded-full';

export const KINDS = {
  PRIMARY: 'primary',
  PRIMARY_OUTLINE: 'primary:outline',
  PRIMARY_GHOST: 'primary:ghost',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINE: 'secondary:outline',
  SECONDARY_GHOST: 'secondary:ghost',
  RAW: 'raw',
  LINK: 'link',
};

const Button = forwardRef(
  (
    {
      innerRef,
      children,
      kind = 'primary',
      shape,
      size,
      isActive,
      isDisabled,
      isLoading,
      className = '',
      ...props
    },
    ref,
  ) => {
    const classNames = useClassNames(
      () => [
        ...(kind === KINDS.LINK ? LINK_BASE_CLASSES : BUTTON_BASE_CLASSES),
        !isDisabled && !isActive && kind === 'primary' && PRIMARY_COLORS,
        !isDisabled && !isActive && kind === 'primary:outline' && PRIMARY_OUTLINE_COLORS,
        !isDisabled && !isActive && kind === 'primary:ghost' && PRIMARY_GHOST_COLORS,
        !isDisabled && !isActive && kind === 'secondary' && SECONDARY_COLORS,
        !isDisabled && !isActive && kind === 'secondary:outline' && SECONDARY_OUTLINE_COLORS,
        !isDisabled && !isActive && kind === 'secondary:ghost' && SECONDARY_GHOST_COLORS,
        !isDisabled && isActive && kind === 'primary' && PRIMARY_COLORS_ACTIVE,
        !isDisabled && isActive && kind === 'primary:outline' && PRIMARY_OUTLINE_COLORS_ACTIVE,
        !isDisabled && isActive && kind === 'primary:ghost' && PRIMARY_GHOST_COLORS_ACTIVE,
        !isDisabled && isActive && kind === 'secondary' && SECONDARY_COLORS_ACTIVE,
        !isDisabled && isActive && kind === 'secondary:outline' && SECONDARY_OUTLINE_COLORS_ACTIVE,
        !isDisabled && isActive && kind === 'secondary:ghost' && SECONDARY_GHOST_COLORS_ACTIVE,
        !isDisabled && isLoading && kind === 'primary' && 'spinner-white',
        !isDisabled && isLoading && kind === 'primary:outline' && 'spinner-primary',
        !isDisabled && isLoading && kind === 'primary:ghost' && 'spinner-primary',
        !isDisabled && isLoading && kind === 'secondary' && 'spinner-white',
        !isDisabled && isLoading && kind === 'secondary:outline' && 'spinner-secondary',
        !isDisabled && isLoading && kind === 'secondary:ghost' && 'spinner-secondary',
        isDisabled && kind !== KINDS.LINK && DISABLED_COLORS,
        isDisabled && kind === KINDS.LINK && DISABLED_COLORS_LINK,
        isDisabled && isLoading && 'spinner-secondary',
        kind !== KINDS.LINK && !shape && !size && SHAPE_DEFAULT_SIZE_DEFAULT,
        shape === 'square' && !size && SHAPE_SQUARE_SIZE_DEFAULT,
        shape === 'circle' && !size && SHAPE_CIRCLE_SIZE_DEFAULT,
        !shape && size === 'sm' && SHAPE_DEFAULT_SIZE_SM,
        shape === 'square' && size === 'sm' && SHAPE_SQUARE_SIZE_SM,
        shape === 'circle' && size === 'sm' && SHAPE_CIRCLE_SIZE_SM,
        !shape && size === 'xs' && SHAPE_DEFAULT_SIZE_XS,
        shape === 'square' && size === 'xs' && SHAPE_SQUARE_SIZE_XS,
        shape === 'circle' && size === 'xs' && SHAPE_CIRCLE_SIZE_XS,
        !shape && size === 'lg' && SHAPE_DEFAULT_SIZE_LG,
        !shape && size === 'auto' && SHAPE_DEFAULT_SIZE_AUTO,
        className,
      ],
      [className, isDisabled, isLoading, isActive, kind, shape, size],
    );

    return (
      <button
        ref={innerRef || ref}
        data-component="Button"
        type="button"
        disabled={isDisabled}
        aria-disabled={isDisabled}
        className={classNames}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: PropTypes.shape({ current: PropTypes.any }),
  kind: PropTypes.oneOf([
    'raw', // no default styles
    'link',
    'primary',
    'primary:outline',
    'primary:ghost',
    'secondary',
    'secondary:ghost',
    'secondary:outline',
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'auto']),
  shape: PropTypes.oneOf(['default', 'square', 'circle']),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  children: PropTypes.node,
};

export default Button;
