import React from 'react';
import PropTypes from 'prop-types';

const FormErrorMessage = ({ message, className }) => (
  <p className={`text-xs text-red-600 ${className}`}>{message}</p>
);

FormErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FormErrorMessage.defaultProps = {
  className: '',
};

export default FormErrorMessage;
