import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OrderSuccessPage from 'components/OrderSuccessPage';
import CheckoutPage from './components/CheckoutPage';
import DefaultErrorPage from './components/ErrorPage/DefaultErrorPage';
import OrderSystemErrorPage from './components/ErrorPage/OrderSystemErrorPage';
import PlanVariantErrorPage from './components/ErrorPage/PlanVariantErrorPage';
import PSPRedirect from './components/PSPRedirect';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="checkout">
        <Route path="finalize" element={<PSPRedirect />} />
        <Route path="success" element={<OrderSuccessPage />} />
        <Route path=":planVariantId" element={<CheckoutPage />} />
      </Route>
      <Route path="errors">
        <Route path="order-system" element={<OrderSystemErrorPage />} />
        <Route path="plan-variant-invalid" element={<PlanVariantErrorPage />} />
      </Route>
      <Route path="*" element={<DefaultErrorPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
