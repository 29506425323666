import { useTranslation } from 'react-i18next';

const OrderSupportInfoText = () => {
  const { t } = useTranslation('checkout');

  return (
    <div>
      <div className="mb-4">
        <strong>{t('CheckoutPage.SupportInfo.text')}</strong>
      </div>
      <div className="flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <div>
          <img className="mt-2 w-14" src="/support_person.png" alt="" />
        </div>
        <div className="ml-4">
          {t('CheckoutPage.SupportInfo.openingHours')}
          <br />
          <strong>{t('CheckoutPage.SupportInfo.phone')} </strong>{' '}
          {t('CheckoutPage.SupportInfo.phoneNumber')}
          <br />
          <strong>{t('CheckoutPage.SupportInfo.email')} </strong>{' '}
          {t('CheckoutPage.SupportInfo.emailaddress')}
        </div>
      </div>
    </div>
  );
};

export default OrderSupportInfoText;
