import { Trans, useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const PlanVariantErrorPage = () => {
  const { t } = useTranslation('checkout');
  const pagesLink = process.env.REACT_APP_PLAN_OVERVIEW;

  return (
    <ErrorPage title={t('CheckoutPage.Errors.PlanVariant.title')}>
      <p>
        <Trans t={t} i18nKey="CheckoutPage.Errors.PlanVariant.text">
          <a
            href={pagesLink}
            target="_blank"
            className="text-blue-500 hover:underline"
            rel="noreferrer"
          >
            Zu unseren aktuellen Preisen und Paketen
          </a>
        </Trans>
      </p>
    </ErrorPage>
  );
};

export default PlanVariantErrorPage;
