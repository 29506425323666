import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { countries, phoneNumber } from '@medflex/commons';
import { memoize, debounce } from 'lodash';
import useForceUpdate from '@medflex/hooks/useForceUpdate';
import DropDown from '@medflex/components/stories/DropDown/DropDown';
import InputField from '@medflex/components/stories/InputField';
import { IconCheveronDown } from '@medflex/components/stories/Icons';

export const LOCAL_STORAGE_NAME = 'mobileNumber_store';
const NAME = 'mobileNumber';
const DEFAULT_REGION = 'DE';
const COUNTRY_CODE_DELIMITER = '#';
const ONLY_NUMBERS_REGEX = /\D/g;
const LEADING_DOUBLE_ZERO_REGEX = /^0{2}/;
const countryCodes = Object.values(phoneNumber.regionsToCountryCode);
// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397;

const localStorageEntry = {
  get: () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)),
  set: currentValues => localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(currentValues)),
};
const getEmoji = countryCode =>
  countryCode
    ?.toUpperCase()
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + OFFSET));
const getCountryCode = region => phoneNumber.regionsToCountryCode[region];
const getOptionValueString = ({ regionCode, countryCode }) =>
  `${countryCode}${COUNTRY_CODE_DELIMITER}${regionCode}`;
const getCountryRegionCode = regionCountryCode => regionCountryCode.split(COUNTRY_CODE_DELIMITER);
const getBrowserRegionOrDefault = memoize(
  defaultVal => (navigator && navigator.languages[0].split('-').pop().toUpperCase()) || defaultVal,
);
const getDefaultValues = localStorageReadEnabled => {
  if (localStorageReadEnabled) {
    const localStorageData = localStorageEntry.get();
    if (localStorageData) return localStorageData;
  }

  const regionCode = getBrowserRegionOrDefault(DEFAULT_REGION);
  return {
    regionCode,
    countryCode: getCountryCode(regionCode),
    regionCountryCode: getOptionValueString({
      regionCode,
      countryCode: getCountryCode(regionCode),
    }),
  };
};
const buildCurrentValuesObject = parsedNumber => ({
  ...parsedNumber,
  regionCountryCode: getOptionValueString(parsedNumber),
});

const normalizeNationalNumber = number => {
  const val = number.replace(LEADING_DOUBLE_ZERO_REGEX, '');
  const firstTwoLetters = val.slice(0, 2);

  return countryCodes.includes(firstTwoLetters) ? `+${val}` : val;
};

const transform = {
  output: ({ countryCode, nationalNumber }) => {
    const parsedNumber = phoneNumber.transformToE164(`+${countryCode}${nationalNumber}`);
    return parsedNumber.isValid ? parsedNumber.phoneNumberE164 : nationalNumber;
  },
  input: (
    type,
    value = '',
    previousValues = {},
    { onInit = false, localStorageReadEnabled } = {},
  ) => {
    const val = value;
    const parsedNumber =
      type === 'mobile' ? phoneNumber.parse(normalizeNationalNumber(val)) : phoneNumber.parse(val);
    // eslint-disable-next-line max-len
    const isRegionCodeSupportedAndNumberValid =
      parsedNumber && Boolean(getCountryCode(parsedNumber.regionCode));

    if (isRegionCodeSupportedAndNumberValid) {
      return buildCurrentValuesObject(parsedNumber);
    }

    const defaultValues = getDefaultValues(localStorageReadEnabled);
    return {
      regionCode: previousValues.regionCode || defaultValues.regionCode,
      countryCode: previousValues.countryCode || defaultValues.countryCode,
      nationalNumber: !onInit ? val.replace(ONLY_NUMBERS_REGEX, '') : defaultValues.nationalNumber,
      regionCountryCode: previousValues.regionCountryCode || defaultValues.regionCountryCode,
    };
  },
};

const optionsData = Object.keys(phoneNumber.regionsToCountryCode).map(regionCode => ({
  countryCode: getCountryCode(regionCode),
  regionCode,
  country: countries[regionCode],
}));

const PhoneNumberField = ({
  name,
  defaultValue,
  localStorageReadEnabled,
  localStorageWriteEnabled,
  type,
  control,
  placeholder,
}) => {
  const forceUpdate = useForceUpdate();
  const {
    field: { ref, ...inputProps },
    fieldState,
  } = useController({ name, control, defaultValue });
  const currentValues = useRef(
    transform.input(type, defaultValue, undefined, {
      onInit: true,
      localStorageReadEnabled,
    }),
  );

  const updateMobileNumberValue = values => inputProps.onChange(transform.output(values));

  useEffect(() => {
    if (currentValues.current.nationalNumber) {
      updateMobileNumberValue(currentValues.current);
    }
  }, []);

  const handleCountryChange = event => {
    const [countryCode, regionCode] = getCountryRegionCode(event.target.value);
    currentValues.current = buildCurrentValuesObject({
      ...currentValues.current,
      countryCode,
      regionCode,
    });

    updateMobileNumberValue(currentValues.current);

    if (!currentValues.current.nationalNumber) forceUpdate();
  };

  const handleNumberChange = event => {
    const { value } = event.target;
    const val = value.trim();

    const parsedVal = transform.input(type, val, currentValues.current);

    // eslint-disable-next-line no-param-reassign
    event.target.value = parsedVal.nationalNumber;

    currentValues.current = parsedVal;

    updateMobileNumberValue(currentValues.current);
  };

  const handleBlur = () =>
    !fieldState.invalid && localStorageWriteEnabled && localStorageEntry.set(currentValues.current);
  const handleFocus = event => !fieldState.invalid && event.target.select();

  return (
    <div className="relative">
      <div className="inline-block absolute w-24">
        <div className="absolute h-full w-full pl-4 flex items-center border-r border-slate-300">
          <span>{getEmoji(currentValues.current.regionCode)}</span>
          <span className="flex-grow text-center">+{currentValues.current.countryCode}</span>
          <IconCheveronDown className="text-slate-600 ml-auto mr-1" />
        </div>
        <DropDown
          onChange={handleCountryChange}
          onBlur={handleBlur}
          name="countryCode"
          initialValue={currentValues.current.regionCountryCode}
          hasArrow={false}
          className="opacity-0"
        >
          {optionsData.map(optionData => {
            const value = getOptionValueString(optionData);
            return [
              <option value={value} key={`${value}`}>
                {`${optionData.country} (+${optionData.countryCode})`}
              </option>,
              optionData.regionCode === 'CH' && (
                <option key="separator" disabled>
                  -------
                </option>
              ),
            ];
          })}
        </DropDown>
      </div>
      <InputField
        {...inputProps}
        placeholder={placeholder}
        onChange={debounce(handleNumberChange, 300)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        name="nationalNumber"
        defaultValue={currentValues.current.nationalNumber}
        inputClassName="pl-26"
      />
    </div>
  );
};

PhoneNumberField.propTypes = {
  control: PropTypes.shape().isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  localStorageReadEnabled: PropTypes.bool,
  localStorageWriteEnabled: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf(['mobile', 'landline']),
};

PhoneNumberField.defaultProps = {
  name: NAME,
  defaultValue: '',
  localStorageReadEnabled: false,
  localStorageWriteEnabled: false,
  type: 'mobile',
};

export default PhoneNumberField;
