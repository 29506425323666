import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ErrorPage from './ErrorPage';

const OrderSystemErrorPage = () => {
  const { t } = useTranslation('checkout');
  const [searchParams] = useSearchParams();

  const planVariantId = searchParams.get('planVariantId');
  const email = searchParams.get('email');
  const pagesLink = `/checkout/${planVariantId}${email ? `?email=${email}` : ''}`;

  return (
    <ErrorPage title={t('CheckoutPage.Errors.OrderSystem.title')}>
      <p>
        <Trans t={t} i18nKey="CheckoutPage.Errors.OrderSystem.text">
          <a href={pagesLink} className="text-blue-500 hover:underline">
            mit diesem Bestelllink
          </a>
        </Trans>
      </p>
    </ErrorPage>
  );
};

export default OrderSystemErrorPage;
