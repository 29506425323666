import DropDown from '@medflex/components/stories/DropDown/DropDown';
import InputCheckbox from '@medflex/components/stories/InputCheckbox';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSubscriptionJS } from '../../hooks/useSubscriptionJs';
import { SubscriptionJS } from '../../types/billwerk';
import FormInput from './FormInput';

export interface PaymentInformation {
  paymentMethod: string;
  accountOwner: string;
  iban: string;
  agreeDebit: boolean;
}

const PaymentInformationForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [subscriptionJS, setSubscriptionJS] = useState<SubscriptionJS>();

  useSubscriptionJS(setSubscriptionJS);
  const { t } = useTranslation('checkout');

  const validateIBAN = (iban: string) => {
    if (subscriptionJS) {
      return subscriptionJS.isIBANValid(iban) ? true : t('CheckoutPage.Form.Errors.iban');
    }

    // Fallback: Ignore IBAN check if SubscriptionJS isn't available
    // will be checked in billwerk before order is accepted
    return true;
  };

  const requiredMessage: string = t('CheckoutPage.Form.Errors.required');

  return (
    <div>
      <div className="mb-4">
        <strong>{t('CheckoutPage.Form.paymentData')}</strong>
      </div>
      <div className="pb-4">
        <label htmlFor="paymentMethod">{t('CheckoutPage.Form.Fields.PaymentMethod.text')} *</label>
        <br />
        <DropDown
          id="paymentMethod"
          {...register('paymentMethod', { required: requiredMessage })}
          options={['Debit']}
          optionLabels={[t('CheckoutPage.Form.Fields.PaymentMethod.Debit.text')]}
          state={errors.paymentMethod ? 'invalid' : 'default'}
          initialValue="Debit"
        />
      </div>
      <FormInput
        name="accountOwner"
        text={t('CheckoutPage.Form.Fields.accountHolder')}
        errors={errors}
        register={register}
        options={{ required: requiredMessage }}
      />
      <FormInput
        name="iban"
        text={t('CheckoutPage.Form.Fields.iban')}
        errors={errors}
        register={register}
        options={{ required: requiredMessage, validate: validateIBAN }}
      />
      <div className="flex flex-row pt-1">
        <div className="pr-3">
          <InputCheckbox
            {...register('agreeDebit', { required: requiredMessage })}
            id="agreeDebit"
            state={errors.agreeDebit ? 'invalid' : 'default'}
          />
        </div>
        <div>
          <label htmlFor="agreeDebit">
            <div className="mb-3">
              {t('CheckoutPage.Form.Fields.PaymentMethod.Debit.agreement')}
            </div>
            <div>{t('CheckoutPage.Form.Fields.PaymentMethod.Debit.details')}</div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformationForm;
