import { useTranslation } from 'react-i18next';
import { formatCurrencyAmount, formatDate } from '../../utils/FormatUtils';
import { Order } from '../../types/billwerk';

type PlanAdditionalInformationProps = {
  order: Order | undefined;
};

const PlanAdditionalInformation = ({ order }: PlanAdditionalInformationProps) => {
  const { t } = useTranslation('checkout');

  return (
    <div>
      {order?.NextTotalGross !== order?.TotalGross && (
        <div className="border-l-2 border-blue-400 mb-3 pl-2">
          {t('CheckoutPage.Plan.nextTerm', {
            date: formatDate(order?.NextTotalGrossDate),
            interpolation: { escapeValue: false },
          })}
          <br />
          {t('CheckoutPage.Plan.nextTermPriceInfo', {
            amount: formatCurrencyAmount(order?.Currency, order?.NextTotalGross || 0),
          })}
        </div>
      )}

      <div className="border-l-2 border-blue-400 mb-3 pl-2">
        {t('CheckoutPage.Plan.termination')}
      </div>
    </div>
  );
};

export default PlanAdditionalInformation;
